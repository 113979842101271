import 'bootstrap';
import './css/style.scss';
import $ from 'jquery';
import './js';

if (module.hot) {
    // eslint-disable-line no-undef
    module.hot.accept();
} // eslint-disable-line no-undef

window.scrollToForm = function(activeTab) {
    $('html, body').animate(
        {
            scrollTop: $('#registeredForm').offset().top
        },
        500
    );
    const $input = $(`#${activeTab}`);
    const $label = $(`label[for=${activeTab}]`);
    $label.addClass('active');
    $input.prop('checked', true);
};

window.onRegistedFormSubmit = function() {
    $('#submitNoti').addClass('show');
};

// setTimeout(function() {
//     $(`label[for=checkbox1]`).addClass('active');
// }, 2000);
