var star = 0;
var token = window.sessionStorage.getItem('token') ? window.sessionStorage.getItem('token') : '';

// const client_id = 'b2cd8f33-862f-4505-8fac-94d42cc2784a';   // UAT
// const client_secret = '5147cea2-6894-4239-b18d-cf6c7c8b5a66';   // UAT

const client_id = 'fc80195a-a7b2-4fb3-be07-52bdded43390';   // PRO
const client_secret = 'a3967b82-f641-4c52-918b-5f6bc1f3b727';   // PRO

// const API_URL = 'https://uat-api.vexere.net';   // UAT
const API_URL = 'https://api.vexere.com';   // PRO

// const JIRA_URL = 'http://localhost:1337';   // UAT
const JIRA_URL = 'https://helpdesk.vexere.com';   // PRO

const PATH_OAUTH = '/v1/oauth/token';
const PATH_FEEDBACK = '/v1/visionhelpdesk/ticket/add';
const PATH_CHECK_EXPIRE = '/v1/visionhelpdesk/check_expire/';
const PATH_CHECK_EXPIRE_JIRA = '/check-expire';
const PATH_RATE_JIRA = '/rate';

$(document).ready(function() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramId = urlParams.get('id');
    const paramKey = urlParams.get('key');

    const staff = urlParams.get('staff');
    const datetime = urlParams.get('call-datetime');

    if (staff) {
        $('.staff').text(staff);
    } else {
        $('.call-info').addClass('d-none');
    }
    if (datetime) {
      const datetimeRegex = /^(\d{2}):(\d{2}) (\d{2})[\/-](\d{2})[\/-](\d{4})$/;
      if (datetimeRegex.test(datetime)) {
        $('.call-datetime').text(datetime);
      } else {
        const displayedDatetime = new Date(parseInt(datetime)).toLocaleString('vi-VN', {
          timeZone: 'Asia/Ho_Chi_Minh',
          hour: '2-digit',
          minute: '2-digit',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour12: false,
        });
        $('.call-datetime').text(displayedDatetime);
      }
    }

    $('.star').on('click', function(e) {
        selectStar($(this).attr('data-value'));
    });

    $('.btn-send-feedback').on('click', function(e) {
        submitForm();
    });
    
    if (!paramId && !paramKey) {
        $('.not-found').removeClass('d-none');
        $('.feedback-container').addClass('d-none');
        return;
    }

    if (!token) {
        $.ajax({
            url: API_URL + PATH_OAUTH,
            method: 'POST',
            data: {
                client_id: client_id,
                client_secret: client_secret,
                grant_type: 'client_credentials'
            },
            dataType: 'json',
            success: function(response) {
                token = response.access_token;
                window.sessionStorage.setItem('token', token);
                if (paramId) {
                    checkIsExpire(paramId);
                } else if (paramKey) {
                    checkIsExpireJira(paramKey);
                }
            } 
        });
    }
    else {
        if (paramId) {
            checkIsExpire(paramId);
        } else if (paramKey) {
            checkIsExpireJira(paramKey);
        }
    }
});

function checkIsExpire(id) {
    $.ajax({
        url: `${API_URL + PATH_CHECK_EXPIRE}${id}`,
        method: 'GET',
        dataType: 'json',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        success: function(response) {
            const { data } = response;
            if (data && !data.isExist) {
                $('.feedback-container').addClass('d-none');
                Swal.fire({
                    title: 'Thông báo',
                    text: 'Đã hết hạn đánh giá',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        },
        error: function(response) {
            $('.feedback-container').addClass('d-none');
            Swal.fire({
                title: 'Thông báo',
                text: 'Có lỗi xảy ra',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    });
}

function checkIsExpireJira(id) {
    const urlParams = new URLSearchParams(window.location.search);
    const paramRate = urlParams.get('rate');

    $.ajax({
        url: `${JIRA_URL + PATH_CHECK_EXPIRE_JIRA}?issueKey=${id}`,
        method: 'GET',
        dataType: 'json',
        success: function(response) {
            if (response && !response.isExist) {
                $('.feedback-container').addClass('d-none');
                Swal.fire({
                    title: 'Thông báo',
                    text: 'Đã hết hạn đánh giá',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
            if (paramRate) {
                selectStar(+paramRate);
            }
        },
        error: function(response) {
            $('.feedback-container').addClass('d-none');
            Swal.fire({
                title: 'Thông báo',
                text: 'Có lỗi xảy ra',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    });
}

// eslint-disable-next-line no-unused-vars
function selectStar(rating) {
    star = +rating;
    $('.star').removeClass('active');
    for (let i = 0; i < star; i++) {
        const instance = $(`.star:nth-child(${  i + 1  })`);
        instance.addClass('active');
    }
}

// eslint-disable-next-line no-unused-vars
function submitForm() {
    const des = $('textarea[name="description"]').val();
    if (!star) {
        $('.error').removeClass('d-none');
        return;
    } else {
        $('.error').addClass('d-none');
    }

    const urlParams = new URLSearchParams(window.location.search);
    const paramId = urlParams.get('id');
    const paramKey = urlParams.get('key');
    if (paramId) {
        $.ajax({
            url: API_URL + PATH_FEEDBACK,
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                id: paramId,
                review_score: star,
                review_note: des,
                review_date: new Date().getTime()
            },
            dataType: 'json',
            success: function(response) {
                Swal.fire({
                    title: 'Thành công',
                    text: 'Gửi phản hồi thành công',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            },
            error: function (request, status, error) {
                const inform = request.responseJSON && request.responseJSON.error && request.responseJSON.error.code === 3100 ? 'Bạn đã gửi phản hồi rồi' : 'Gửi phản hồi thất bại';
                Swal.fire({
                    title: 'Thất bại',
                    text: inform,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        });
    }
    else if (paramKey) {
        $.ajax({
            url: JIRA_URL + PATH_RATE_JIRA,
            method: 'POST',
            data: {
                issueKey: paramKey,
                rate: star,
                comment: des,
            },
            dataType: 'json',
            success: function(response) {
                Swal.fire({
                    title: 'Thành công',
                    text: 'Gửi phản hồi thành công',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            },
            error: function (request, status, error) {
                const inform = request.responseJSON && request.responseJSON.code === 400 ? 'Bạn đã gửi phản hồi rồi' : 'Gửi phản hồi thất bại';
                Swal.fire({
                    title: 'Thất bại',
                    text: inform,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        });
    }
    return false;
}
